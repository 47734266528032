<template>
  <div>
    <h2 class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12">
      Lupa Kata Sandi? Kami Akan Bantu
    </h2>
    <form novalidate="novalidate" id="popup_login">
      <b-form-group label="Email Pendaftar" label-for="email-input">
        <b-form-input id="email-input" type="email" v-model="$v.form.email.$model" :state="validateState('email')"
          placeholder="Silahkan masukan email pendaftar"></b-form-input>
      </b-form-group>
      <b-form-group label="No. SKT Aktif" label-for="password-input">
        <b-form-input id="name-input" type="text" v-model="$v.form.skt.$model" :state="validateState('skt')"
          placeholder="Silahkan masukan Nomor SKT Aktif"></b-form-input>
      </b-form-group>

      <div class="text-centerLupa">
        <b-button class="button-back tombol-DiLupa" style="margin-right: 2.5% !important" size="lg"
          @click="$router.back()">Kembali</b-button>
        <b-button class="ml-8 button-submit  tombol-DiLupa" style="margin-left: 2.5% !important" type="button"
          v-on:click="handleSubmit" size="lg">Kirim
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
// import KTUtil from "@/assets/js/components/util";
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "ForgotPasswordFloat",
  data() {
    return {
      form: {
        email: "",
        skt: ""
      },
      nameState: null,
      submittedNames: [],
      loading: false
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      skt: {
        required,
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetModal() {
      this.email = "";
      this.password = "";
      this.nameState = null;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      this.loading = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loading = false
        return;
      } else {
        this.submit()
      }
      // Push the name to submitted names
      // this.submittedNames.push(this.email);
      // this.submittedNames.push(this.password);
      // Hide the modal manually
      // this.$nextTick(() => {
      //   this.$bvModal.hide("modal-prevent-closing");
      // });
    },
    submit: function() {
      // e.preventDefault();
      let body = {
        "username": this.form.skt,
        "email": this.form.email,
        "url_callback": process.env.VUE_APP_APPLICATION_BASE_URL + "/resetsandi",
        "nonOrmas": false
      }
      this.$store.dispatch('lupaPassword', body).then(() =>{
        this.$router.push('/login');
        Swal.fire({
          icon: "success",
          title: "Tautan Telah Dikirim ke Email Anda",
          text: "Silahkan cek email anda untuk melihat tautan untuk reset sandi",
          confirmButtonColor: "#063A69",
          confirmButtonText: "Kembali Ke Beranda"
        })

      }).catch((err) =>{
        Swal.fire({
          title: "Gagal Submit",
          text: err,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
      
    }
  },
  mounted() {

  }
};
</script>

<style scoped>

.tombol-DiLupa {
  width: 29.917%;
  height: auto !important;
  margin-left: 0px !important;
  text-align: center;
  padding: 3% !important;
}

.text-centerLupa {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.button-submit {
  margin-top: 20px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}

.button-back {
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid rgba(6, 58, 105, 0.6);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 50px;
}
</style>
